<template>
  <section
    class="tm-popularLayoutsProjectBlock"
    :class="$customSectionClasses(cfgs)"
    data-key-anchor="Популярные планировки (с детальной информацией)"
  >
    <div class="container">
      <div
        v-if="values.title"
        class="h2"
        v-html="values.title"
      >
      </div>
      <client-only>
        <template v-if="isPageLoaded">
          <PopularLayoutsFilters
            class="popular_filters"
            :filters="popularFiltersList"
            :selected-filters="state.selectedFilter as Record<string, object>"
            :products-length="pagination.totalCount"
            :is-show-only-filters="true"
            :url-to="extras.url"
            :is-disabled-filters="Boolean(state.isLoadProducts)"
            :amount-product-without-group="totalProductsAmountWithoutGroup"
            @changeFilter="changeFilter"
          />
          <div>
            <template v-if="isPageLoaded && pagination.totalCount > 0">
              <MainProductCard
                v-if="!state.isLoadProducts"
                :flats-list="products"
              />
              <div
                v-else
                class="popular-card__loading"
              >
                <img
                  src="~/assets/img/svg/loading/body-loading.svg"
                  alt="loader"
                >
              </div>
            </template>

            <div
              v-else
              class="nothing-not-found"
            >
              <h2>Нет предложений</h2>
            </div>
          </div>
        </template>
        <div
          v-else
          class="popular-card__loading"
        >
          <img src="~/assets/img/svg/loading/body-loading.svg" />
        </div>
      </client-only>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import MainProductCard from '~/cms/blocks/PopularLayoutsProjectBlock/MainProductCard.vue';
import useSitisCategory from '#sitis/composables/useSitisCategory';
import type { FilterPricesType } from '~/types/Commons';
import type { SelectedFilterListItem } from '~/utils/formaters/setSelectedFiltersList';

type PopularLayoutspPojectBlock = Omit<IBlockDefaultProps, 'values' | 'cfgs'|'extras'> & {
	values:{
		category_id?:number;
		title?:string;
		filter_page_id?:number;
		type_products?:number;
	};
	cfgs: SectionTypes;
	extras:{
		slug:string;
		url:string;
	}
}

const { $customSectionClasses, $api } = useNuxtApp();
const props = defineProps<PopularLayoutspPojectBlock>();
const isPageLoaded = ref<boolean>(false);
const totalProductsAmountWithoutGroup = ref<number>(0);

const selectedFiltersList = ref<Record<string, object>>({
  group_planing_product: { da: 'da' }
});
const slugArray = props.extras.slug.split('/');

const filterCategorySlug = ref<string>(slugArray.slice(-1).toString());
const defaultBasePathApi = ref<string>(slugArray.slice(0, -1).join('/'));
const {
  state,
  products,
  filters,
  pagination,

  _getSelectedFiltersForApi,
  onClearProductsList,
  getProducts,
  changeSelectedFilterFull,
  initSelectedFilter,
  getFilters,
  resetSelectedFilter
} = useSitisCategory({
  categorySlug: filterCategorySlug.value,
  defaultBasePathApi: defaultBasePathApi.value,
  defaultPerPage: 999,
  isCancelApplyFilterToURL: true,
  extensions: ['characteristics']
});
const popularFiltersList = computed(() => filters.value.filter((t) => ['rooms', 'area-total', 'property-price'].includes(t.slug)));

const getProductWithoutGroups = async () => {
  const filtersObj = { ...state.value.selectedFilter };
  if (filtersObj && 'group_planing_product' in filtersObj) {
    delete filtersObj.group_planing_product;
  }

  const selectedFiltersStr = _getSelectedFiltersForApi(filtersObj).join('&');

  const resp = await $api.productsApi.getProducts(filterCategorySlug.value, {
    basePath: defaultBasePathApi.value,
	  filters: [selectedFiltersStr],
	  perPage: 900

  })
    .catch((e) => {
      console.log('get-products-without-groups error:', errorParserServerResponse(e?.response ?? e));
       return null;
    });

  if (resp) {
	  totalProductsAmountWithoutGroup.value = Number(resp.pagination?.totalCount);
  }
};
const initLocalSelectedFilters = () => {
  const initCategoryId = props.values.category_id;

  if (!initCategoryId) { return; }

  const categories = filters.value.find((t) => t.slug === 'categories') || null;
  if (!categories) { return; }

  const categoriesVariants = categories?.variants || [];
  const categoriesVariantsSelected = categoriesVariants.filter((t) => t?.parentId === initCategoryId);
  if (categoriesVariantsSelected.length <= 0) { return; }

  const newSlugsCategories: Record<string, string> = {};

  categoriesVariantsSelected.forEach((t) => {
    newSlugsCategories[t.slug] = t.slug;
  });

  selectedFiltersList.value = {
    ...selectedFiltersList.value,
    categories: newSlugsCategories
  };
};

const changeFilter = async (options: Record<string, object> | SelectedFilterListItem) => {
  changeSelectedFilterFull(options);
  await getFilters();
  await getProducts();
  await getProductWithoutGroups();
};

onMounted(async () => {
  await getFilters();
  initLocalSelectedFilters();
  changeSelectedFilterFull(selectedFiltersList.value);
  await getProducts();

  await getProductWithoutGroups();
  isPageLoaded.value = true;
});

onBeforeUnmount(() => {
  onClearProductsList();
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.popularlayout-project__loading {
	position: absolute;
	inset: 0;
	z-index: 10;
	background-color: rgb(255 255 255 / 80%);
	display: flex;
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(12px);
	border-radius: 20px;

	img {
		width: 160px;
	}
}

.tm-popularLayoutsProjectBlock {
	background-color: #F5F4F8;
	z-index: 12;

	& .popular_filters{
		margin-bottom: 40px;
	}

	& .nothing-not-found{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.popular-card__loading{
		min-height: 528px;
		background: var(--white);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 25px;
	}
}

.btn-sort__popular{
	margin-bottom: 24px;
}

.tm-popularLayoutsProjectBlock__contentWrapper {
	display: flex;
	margin-left: -30px;
}

.tm-popularLayoutsProjectBlock__left {
	margin-left: 30px;
	width: calc(33.33% - 30px);
	max-width: 420px;
}

.tm-popularLayoutsProjectBlock__right {
	margin-left: 30px;
	width: calc(66% - 30px);
}

@include media('lg') {
	.tm-popularLayoutsProjectBlock__contentWrapper {
		flex-direction: column;
		margin-left: 0;

		& > div {
			margin-left: 0;
		}
	}

	.tm-popularLayoutsProjectBlock__left {
		width: 100%;
		max-width: initial;
	}

	.tm-popularLayoutsProjectBlock__right {
		width: 100%;
	}
}

@include media('md') {
	.tm-popularLayoutsProjectBlock{
		& .h1{
			margin-bottom: 40px;
		}
	}
}

@include media('sm') {
	.tm-popularLayoutsProjectBlock {
		padding: 40px 0;
	}
}
</style>>
